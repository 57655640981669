import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/experience',
        name: 'Experience-fr',
        component: () => import(/* webpackChunkName: "experience" */ '../views/Experience.vue'),
        meta: { lang: 'fr', json: 'experience' },
    },
    {
        path: '/en/experience',
        name: 'Experience-en',
        component: () => import(/* webpackChunkName: "experience" */ '../views/Experience.vue'),
        meta: { lang: 'en', json: 'experience' },
    },
    {
        path: '/programmation',
        name: 'Programmation-fr',
        component: () =>
            import(/* webpackChunkName: "programmation" */ '../views/Programmation.vue'),
        meta: { lang: 'fr', json: 'programmation' },
    },
    {
        path: '/en/programmation',
        name: 'Programmation-en',
        component: () =>
            import(/* webpackChunkName: "programmation" */ '../views/Programmation.vue'),
        meta: { lang: 'en', json: 'programmation' },
    },
    {
        path: '/artistes/:slug',
        name: 'Artistes-fr',
        component: () => import(/* webpackChunkName: "artistes" */ '../views/Artistes.vue'),
        meta: { lang: 'fr', json: 'artistes', slug: true },
    },
    {
        path: '/en/artists/:slug',
        name: 'Artistes-en',
        component: () => import(/* webpackChunkName: "artistes" */ '../views/Artistes.vue'),
        meta: { lang: 'en', json: 'artistes', slug: true },
    },
    {
        path: '/info-utiles',
        name: 'InfoUtiles-fr',
        component: () => import(/* webpackChunkName: "info-utiles" */ '../views/InfoUtiles.vue'),
        meta: { lang: 'fr', json: 'infoUtiles' },
    },
    {
        path: '/en/useful-info',
        name: 'InfoUtiles-en',
        component: () => import(/* webpackChunkName: "info-utiles" */ '../views/InfoUtiles.vue'),
        meta: { lang: 'en', json: 'infoUtiles' },
    },
    {
        path: '/medias',
        name: 'Media-fr',
        component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
        meta: { lang: 'fr', json: 'medias' },
    },
    {
        path: '/en/medias',
        name: 'Media-en',
        component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
        meta: { lang: 'en', json: 'medias' },
    },
    {
        path: '/infolettre',
        name: 'Infolettre-fr',
        component: () => import(/* webpackChunkName: "infolettre" */ '../views/Infolettre.vue'),
        meta: { lang: 'fr', json: 'infolettre' },
    },
    {
        path: '/en/newsletter',
        name: 'Infolettre-en',
        component: () => import(/* webpackChunkName: "infolettre" */ '../views/Infolettre.vue'),
        meta: { lang: 'en', json: 'infolettre' },
    },
    {
        path: '/reglements',
        name: 'Reglements-fr',
        component: () => import(/* webpackChunkName: "reglements" */ '../views/Reglements.vue'),
        meta: { lang: 'fr', json: 'reglements' },
    },
    {
        path: '/en/rules',
        name: 'Reglements-en',
        component: () => import(/* webpackChunkName: "reglements" */ '../views/Reglements.vue'),
        meta: { lang: 'en', json: 'reglements' },
    },
    {
        path: '/politiques-de-confidentialite',
        name: 'Politiques-fr',
        component: () => import(/* webpackChunkName: "politiques" */ '../views/Politiques.vue'),
        meta: { lang: 'fr', json: 'politiquesDeConfidentialite' },
    },
    {
        path: '/en/privacy-policies',
        name: 'Politiques-en',
        component: () => import(/* webpackChunkName: "politiques" */ '../views/Politiques.vue'),
        meta: { lang: 'en', json: 'politiquesDeConfidentialite' },
    },
    {
        path: '/conditions-d-utilisation',
        name: 'Conditions-fr',
        component: () => import(/* webpackChunkName: "conditions" */ '../views/Conditions.vue'),
        meta: { lang: 'fr', json: 'conditionsDutilisation' },
    },
    {
        path: '/en/conditions-d-utilisation',
        name: 'Conditions-en',
        component: () => import(/* webpackChunkName: "conditions" */ '../views/Conditions.vue'),
        meta: { lang: 'en', json: 'conditionsDutilisation' },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 1)

    next()
})

export default router
