<template>
    <div id="app">
        <nav-bar></nav-bar>
        <div class="main-wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>
        <footer-bar></footer-bar>

        <!-- <div class="splash" v-if="splash">
            <img src="@/assets/img/splash-temporaire.jpg" alt="Logo">
        </div> -->

        <section
            class="pop-up"
            :class="{ active: showpopup && pageIsLoaded && globals.mainNav.popup.activer }"
        >
            <div class="pop">
                <img
                    src="@/assets/img/close-dark.svg"
                    class="close"
                    alt="close icon"
                    @click.prevent="closepopup"
                />
                <p class="title">{{ pageIsLoaded ? globals.mainNav.popup.titre : '' }}</p>
                <p class="text pop-text" v-if="pageIsLoaded && globals.mainNav.popup.texte"><span v-html="pageIsLoaded ? globals.mainNav.popup.texte : ''"></span></p>
                <a
                    :href="pageIsLoaded ? globals.mainNav.popup.lienDuBouton : ''"
                    target="_blank"
                    class="button-cta dark"
                    v-if="pageIsLoaded && globals.mainNav.popup.lienDuBouton"
                >
                    <p class="title">
                        {{ pageIsLoaded ? globals.mainNav.popup.titreDuBouton : '' }}
                    </p>
                </a>
            </div>
            <div class="overlay" @click.prevent="closepopup"></div>
        </section>

        <PageLoader v-if="!contentIsLoaded" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'
import PageLoader from '@/components/PageLoader'

export default {
    data() {
        return {
            splash: false,
            showpopup: false,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        if ( localStorage.getItem('cigale.popup') !== 'closed' ) {
                            this.showpopup = true
                        }
                    }, 6475)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        closepopup() {
            this.showpopup = false

            // LocalStorage
            localStorage.setItem('cigale.popup', 'closed')
        },
    },

    components: {
        NavBar,
        FooterBar,
        PageLoader,
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    mounted() {
        // if (window.location.href.includes('demo') || window.location.href.includes('localhost')) {
        //     this.splash = false;
        // } else {
        //     this.splash = true;
        // }

        let direction = ''
        let last_known_scroll_position = 0
        let ticking = false

        function checkScrollPos(scroll_pos) {
            document.querySelector('html').setAttribute('data-direction', direction)

            if (scroll_pos >= 155) {
                document.querySelector('body').classList.add('hide-nav')
            } else {
                document.querySelector('body').classList.remove('hide-nav')
            }
        }

        window.addEventListener(
            'scroll',
            () => {
                const top = window.scrollY
                const navBarHeight = document.querySelector('.navbar').offsetHeight

                if (top >= navBarHeight) {
                    document.querySelector('body').classList.add('nav-is-fixed')
                    document.querySelector('body').style.paddingTop = `${navBarHeight}px`
                } else {
                    document.querySelector('body').classList.remove('nav-is-fixed')
                    document.querySelector('body').style.paddingTop = '0px'
                }

                if (last_known_scroll_position < window.scrollY) {
                    direction = 'down'
                } else if (last_known_scroll_position > window.scrollY + 25) {
                    direction = 'up'
                }

                last_known_scroll_position = window.scrollY

                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        checkScrollPos(last_known_scroll_position)
                        ticking = false
                    })

                    ticking = true
                }
            },
            false
        )
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: scale(1.0125) rotate(0.525deg);
}

.splash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999;

    background: $colorAccent4;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;

        display: none;
    }
}
</style>
