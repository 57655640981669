<template>
    <div>
        <header class="navbar">
            <router-link :to="{ name: 'HomePage-' + $route.meta.lang }"
                ><img v-if="globalsIsLoaded" :src="globals.mainNav.logo" class="logo" alt="Logo Cigale"
            /></router-link>

            <nav class="main-nav" v-if="globalsIsLoaded">
                <router-link
                    :to="item.jsonUrl"
                    class="title"
                    :class="$route.path.includes('artistes') && item.jsonUrl.includes('programmation') || $route.path.includes('artists') && item.jsonUrl.includes('programmation') ? 'router-link-active' : ''"
                    v-for="(item, i) in globals.mainNav.navigationPrincipale"
                    :key="i"
                >
                    {{ item.titreNav }}
                </router-link>
            </nav>

            <a v-if="contentIsLoaded && $route.meta.lang === 'fr'" :href="content.page.urls['en']" class="lang title" title="EN">EN</a>
            <a v-else-if="contentIsLoaded" :href="content.page.urls['fr']" class="lang title" title="FR">FR</a>
            <a v-else :href="'#'" class="lang title" title="">&nbsp;</a>

            <a v-if="globalsIsLoaded" :href="globals.mainNav.lienUrl" target="_blank" class="cta title">{{
                globals.mainNav.lien.customText
            }}</a>

            <div class="burger" @click.prevent="showMobileMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>

        <!-- Mobile Menu -->
        <div class="mobile-menu-wrap" :class="{ show: menuIsOpen }" v-if="globalsIsLoaded">
            <div class="mobile-nav">
                <img
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="Close"
                    @click.prevent="showMobileMenu"
                />
                <a href="/"
                    ><img :src="globals.mainNav.logo" class="logo" alt="Logo Cigale"
                /></a>
                <nav>
                    <a
                        :href="item.jsonUrl"
                        class="title"
                        :class="{ 'is-active': $route.path === item.jsonUrl }"
                        v-for="(item, i) in globals.mainNav.navigationPrincipale"
                        :key="i"
                    >
                        {{ item.titreNav }}
                    </a>
                </nav>
                <a :href="globals.mainNav.lienUrl" target="_blank" class="button-cta dark">
                    <p class="title">{{ globals.mainNav.lien.customText }}</p>
                </a>
            </div>
            <div class="opacity" @click.prevent="showMobileMenu"></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            menuIsOpen: false,
        }
    },

    methods: {
        showMobileMenu() {
            if (this.menuIsOpen) {
                this.menuIsOpen = false
            } else {
                this.menuIsOpen = true
            }
        },
    },

    computed: {
        ...mapState(['globalsIsLoaded', 'contentIsLoaded']),
        ...mapGetters(['globals', 'content']),
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';
@import '@/assets/styles/03-Settings/typos.scss';
</style>
