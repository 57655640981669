<template>
    <footer class="main-footer">
        <div class="top">
            <img src="@/assets/img/footer-wave.png" alt="La Cigale" />
        </div>

        <div class="partenaires" v-if="globalsIsLoaded">
            <h2 class="title small main-title" data-inview="fadeInUp" data-delay="100">{{ globals.footer.titreLogos }}</h2>
            <div class="top" data-inview="fadeInUp" data-delay="200">
                <a
                    :href="item.lien"
                    target="_blank"
                    class="logo"
                    v-for="(item, i) in globals.footer.logosGros"
                    :key="i"
                >
                    <img :src="item.url" :alt="item.titre" />
                </a>
            </div>
            <div class="bottom" data-inview="fadeInUp" data-delay="400">
                <div class="carousel carousel-flk1" ref="flickity2">
                    <div
                        class="carousel-cell"
                        v-for="(listOfItems, j) in logosPetits"
                        :key="j + '-cs'"
                    >
                        <a
                            :href="item.lien"
                            target="_blank"
                            class="logo"
                            v-for="(item, i) in listOfItems"
                            :key="i"
                        >
                            <img :src="item.url" :alt="item.titre" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-wrap" v-if="globalsIsLoaded">
            <div class="montagne">
                <img src="@/assets/img/footer-montagne.png" alt="La Cigale montagne" />
            </div>

            <div class="content">
                <div class="left" data-inview="slideLeft" data-delay="200">
                    <img src="@/assets/img/cigale-aile.svg" class="icon" alt="La Cigale" />
                    <h3 class="title small">{{ globals.footer.infolettre.titre }}</h3>
                    <div class="social">
                        <a
                            :href="item.lien"
                            target="_blank"
                            v-for="(item, i) in globals.footer.liensReseaux"
                            :key="i"
                        >
                            <img :src="item.image.url" :alt="item.image.titre"
                        /></a>
                    </div>
                    <a
                        :href="globals.footer.infolettre.lienUrl[$route.meta.lang]"
                        target="_blank"
                        class="button-cta pink"
                        v-if="$route.meta.lang !== 'en'"
                    >
                        <p class="title">{{ globals.footer.infolettre.texte }}</p>
                    </a>
                </div>
                <a :href="globals.footer.lienLogoExperience" target="_blank" class="gestev" rel="noopener noreferrer"
                    ><img
                        :src="globals.footer.logo"
                        alt="Gestev logo"
                        data-inview="scale"
                        data-delay="200"
                /></a>
                <div class="right" data-inview="slideRight" data-delay="200">
                    <img src="@/assets/img/map-point.svg" class="icon" alt="La Cigale" />
                    <h3 class="title small">{{ globals.footer.informations.titre }}</h3>
                    <address>
                        <p class="text">
                            <a href="https://www.google.com/maps/place/1+Boul.+Henri-Bourassa,+Qu%C3%A9bec,+QC+G1J+5L7,+Canada/@46.8374724,-71.1984404,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb8be00eb4c9f03:0x4deb4e5aede56d0e!8m2!3d46.8374724!4d-71.1962517" target="_blank"><span v-html="globals.footer.informations.adresse"></span></a>
                        </p>
                    </address>
                    <div class="contact">
                        <a :href="'mailto:' + globals.footer.informations.courriel" class="title">{{
                            globals.footer.informations.courriel
                        }}</a>
                        <a :href="'tel:' + globals.footer.informations.telephone" class="title">{{
                            globals.footer.informations.telephone
                        }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="credits-bar" v-if="globalsIsLoaded">
            <div class="left">
                <p class="text">{{ globals.footer.texte }}</p>

                <router-link
                    :to="item.jsonUrl"
                    class="text"
                    v-for="item in globals.footer.navigationPrincipale"
                    :key="item.titreNav"
                >
                    {{ item.titreNav }}
                </router-link>
            </div>
            <a href="https://elmire.ca/" target="_blank" class="right" rel="noopener noreferrer">
                <p class="text">Conception Elmire</p>
                <img src="@/assets/img/elmire-logo.png" alt="Elmire logo" />
            </a>
        </div>
    </footer>
</template>

<script>
import Flickity from 'flickity'

import { mapState, mapGetters } from 'vuex'

export default {
    watch: {
        globalsIsLoaded: {
            handler(globalsIsLoaded) {
                if (globalsIsLoaded) {
                    setTimeout(() => {
                        this.flk1 = new Flickity(document.querySelector('.carousel-flk1'), {
                            prevNextButtons: false,
                            pageDots: this.logosPetits.length >= 2,
                            contain: true,
                            autoPlay: true,
                            wrapAround: true,
                            imagesLoaded: true,
                        })
                    }, 155)

                    setTimeout(() => {
                        this.flk1.resize()
                    }, 2555)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            flk1: null,
        }
    },

    computed: {
        logosPetits() {
            if (!this.globalsIsLoaded) {
                return []
            }

            const myArray = []
            const size = 12
            const arr = this.globals.footer.logosPetits

            for (let i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i + size))
            }

            return myArray
        },
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['globals']),
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/02-Tools/_variables.scss';
@import '@/assets/styles/03-Settings/typos.scss';

.main-footer {
    width: 100%;
    margin-top: -100px;

    .partenaires {
        width: 100%;
        background-color: $colorAccent5;

        .main-title {
            width: 100%;
            text-align: center;
            padding: 3vw 0;
            font-size: 24px;
        }

        .top {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            .logo {
                width: 300px;
            }
        }

        .bottom {
            width: 100%;
            padding-bottom: 175px;
            .carousel {
                width: 75%;
                margin: 0 auto;
                .carousel-cell {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    .logo {
                        width: 15%;
                        // min-height: 7vw;
                    }
                }
            }
        }
    }
    .contact-wrap {
        margin-top: -150px;

        .content {
            width: 100%;
            padding: 0 7vw;
            display: flex;
            justify-content: space-between;
            background: $colorAccent1;
            padding-bottom: 5vw;
            position: relative;
            flex-wrap: wrap;

            .gestev {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -100px;
                margin-top: -43px;
                width: 200px;
            }

            .left {
                text-align: left;
                .icon {
                    width: 60px;
                    margin-bottom: 10px;
                }
                .title {
                    color: $colorAccent6;
                }
                .social {
                    display: flex;
                    margin: 30px 0;

                    img {
                        width: 30px;
                        margin-right: 15px;
                        transform: scale(1,1);
                        transition: all 0.2s ease;

                        &:hover {
                            transform: scale(1.3,1.3);
                        }
                    }
                }
                .button-cta {
                    .title {
                        color: $colorAccent1;
                    }
                }
            }
            .right {
                text-align: right;

                .icon {
                    width: 40px;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                .title {
                    color: $colorAccent2;
                }
                address {
                    margin: 20px 0;
                    a {
                        color: $colorAccent4;
                    }
                    .text {
                        color: $colorAccent4;
                    }
                }
                .contact {
                    .title {
                        color: $colorAccent2;
                        font-size: 20px;
                        display: block;
                        transition: all 0.2s ease;

                        &:hover {
                            color: $colorAccent6;
                        }
                    }
                }
            }
        }
    }
    .credits-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 3vw;

        .text {
            font-size: 14px;
        }

        .left {
            display: flex;
            align-items: center;
            .text {
                margin-right: 20px;
            }
            a {
                transition: all 0.2s ease;
                &:hover {
                    color: $colorAccent3;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;

            img {
                width: 25px;
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .contact-wrap {
            .content {
                .gestev {
                    margin-left: -75px;
                    margin-top: -32px;
                    width: 150px;
                }
                .title {
                    &.small {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        .contact-wrap {
            .content {
                padding-top: 40px;
                padding-bottom: 50px;
                .left {
                    width: 100%;
                    text-align: center;
                    .icon {
                        width: 60px;
                        margin-bottom: 10px;
                        display: inline-block;
                    }
                    .title {
                        color: $colorAccent6;
                    }
                    .social {
                        width: 100%;
                        justify-content: center;
                        display: flex;
                        margin: 20px 0;
                        img {
                            width: 30px;
                        }
                    }
                }
                .right {
                    width: 100%;
                    text-align: center;
                }
                .gestev {
                    margin-left: 0;
                    margin-top: 0;
                    width: 150px;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: 40px auto;
                }
                .title {
                    &.small {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .partenaires {
            .top {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                .logo {
                    width: 40%;
                }
            }

            .bottom {
                width: 100%;
                padding-bottom: 175px;
                .carousel {
                    width: 75%;
                    margin: 0 auto;
                    .carousel-cell {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        .logo {
                            width: 15%;
                            // min-height: 7vw;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        .credits-bar {
            flex-wrap: wrap;
            padding: 15px 5vw;

            .text {
                width: 100%;
            }

            .left {
                flex-wrap: wrap;
                width: 100%;
                .text {
                    margin-right: 0px;
                    width: 100%;
                    margin-bottom: 10px;
                    width: 100%;
                    text-align: center;
                }
                a {
                    width: 100%;
                }
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                .text {
                    width: auto;
                }
                img {
                    width: 25px;
                    margin-left: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .partenaires {
            .top {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                flex-wrap: wrap;
            }

            .bottom {
                width: 100%;
                padding-bottom: 200px;
                .carousel {
                    .carousel-cell {
                        width: 100%;

                        .logo {
                            width: 25%;
                            // min-height: 7vw;
                        }
                    }
                }
            }
        }
    }
}

.flickity-page-dots .dot {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid #000;
}
.flickity-page-dots .dot.is-selected {
    background: #000;
}
</style>
